export const MESSAGE_TYPE = {
  ERROR: "error",
  WARNING: "warning",
  INFO: "info",
  SUCCESS: "success",
};

export const ROLE_TYPE = {
  MANAGER: "MANAGER",
  MEMBER: "MEMBER",
};

export const ROLE_TYPE_OPTIONS = new Map([
  ["ALL", { value: "ALL", label: "全部" }],
  [ROLE_TYPE.MANAGER, { value: "MANAGER", label: "管理員" }],
  [ROLE_TYPE.MEMBER, { value: "MEMBER", label: "成員" }],
]);
export const LOCAL_STORAGE_AUTH_KEY = "admin-auth";
export const LOCAL_STORAGE_EMAIL = "admin-email";
export const LOCAL_STORAGE_ROLE = "admin-role";

export const MEMBER_KYC_STATUS = new Map([
  ["ALL", { value: "ALL", label: "全部" }],
  ["IN_REVIEW", { value: "IN_REVIEW", label: "待審核" }],
  ["ACCEPTED", { value: "ACCEPTED", label: "通過" }],
  ["REJECTED", { value: "REJECTED", label: "未通過" }],
]);

export const GENDER_OPTIONS = new Map([
  ["ALL", { value: "ALL", label: "全部" }],
  ["MALE", { value: "MALE", label: "男" }],
  ["FEMALE", { value: "FEMALE", label: "女" }],
]);

export const AGE_OPTIONS = new Map([
  ["ALL", { value: "ALL", label: "全部", startAge: null, endAge: null }],
  ["22", { value: "22", label: "22歲以下", startAge: 0, endAge: 22 }],
  ["30", { value: "30", label: "22~30歲", startAge: 22, endAge: 30 }],
  ["40", { value: "40", label: "30~40歲", startAge: 30, endAge: 40 }],
  ["50", { value: "50", label: "40~50歲", startAge: 40, endAge: 50 }],
  ["60", { value: "60", label: "50~60歲", startAge: 50, endAge: 60 }],
  ["70", { value: "70", label: "60~70歲", startAge: 60, endAge: 70 }],
  ["200", { value: "200", label: "70歲以上", startAge: 70, endAge: 200 }],
]);

export const OCCUPATION_OPTIONS = new Map([
  ["ALL", { value: "ALL", label: "全部" }],
  ["STUDENT", { value: "STUDENT", label: "學生" }],
  ["OFFICE_WORKER", { value: "OFFICE_WORKER", label: "上班族" }],
  ["FREELANCER", { value: "FREELANCER", label: "自由業" }],
  ["OTHER", { value: "OTHER", label: "其他" }],
]);

export const ACTION_COUNT_OPTIONS = new Map([
  [
    "ALL",
    {
      value: "ALL",
      label: "全部",
      activeContractCountMin: null,
      activeContractCountMax: null,
    },
  ],
  [
    "0",
    {
      value: "0",
      label: "0次",
      activeContractCountMin: 0,
      activeContractCountMax: 0,
    },
  ],
  [
    "1",
    {
      value: "1",
      label: "1次",
      activeContractCountMin: 1,
      activeContractCountMax: 1,
    },
  ],
  [
    "2",
    {
      value: "2",
      label: "2次",
      activeContractCountMin: 2,
      activeContractCountMax: 2,
    },
  ],
  [
    "3",
    {
      value: "3",
      label: "3次",
      activeContractCountMin: 3,
      activeContractCountMax: 3,
    },
  ],
  [
    "4",
    {
      value: "4",
      label: "4次",
      activeContractCountMin: 4,
      activeContractCountMax: 4,
    },
  ],
  [
    "5",
    {
      value: "5",
      label: "5次",
      activeContractCountMin: 5,
      activeContractCountMax: 5,
    },
  ],
  [
    "6",
    {
      value: "6",
      label: "6次",
      activeContractCountMin: 6,
      activeContractCountMax: 6,
    },
  ],
  [
    "7",
    {
      value: "7",
      label: "6次以上",
      activeContractCountMin: 7,
      activeContractCountMax: null,
    },
  ],
]);

// 審核結果
export const KYC_RESULT_OPTIONS = new Map([
  // ["SELECT", { value: "SELECT", label: "請選擇" }],
  ["ACCEPTED", { value: true, label: "通過" }],
  ["REJECTED", { value: false, label: "未通過" }],
]);

// 合約狀態
export const CONTRACT_STATUS_TYPE = new Map([
  ["IN_REVIEW", { value: "IN_REVIEW", label: "待審核" }],
  ["REJECTED", { value: "REJECTED", label: "未通過" }],
  ["AWAITING_APPOINTMENT", { value: "AWAITING_APPOINTMENT", label: "待預約" }],
  ["AWAITING_FORMATION", { value: "AWAITING_FORMATION", label: "待成立" }],
  ["ACTIVE", { value: "ACTIVE", label: "使用中" }],
  ["EXPIRED", { value: "EXPIRED", label: "已到期" }],
  ["TERMINATED", { value: "TERMINATED", label: "已終止" }],
  ["CANCELLED", { value: "CANCELLED", label: "已取消" }],
  ["EARLY_TERMINATED", { value: "EARLY_TERMINATED", label: "已提前終止" }],
]);

// 合約狀態選項 使用中 即將到期 已到期 已結束
export const CONTRACT_STATUS_OPTIONS = new Map([
  ["ALL", { value: ["ALL"], label: "全部" }],
  ["ACTIVE", { value: ["ACTIVE"], label: "使用中" }],
  ["EXPIRED", { value: ["EXPIRED"], label: "已到期" }],
  [
    "TERMINATED",
    { value: ["TERMINATED", "EARLY_TERMINATED"], label: "已結束" },
  ],
]);

// 合約狀態選項 使用中 即將到期 已到期 已結束
export const CONTRACT_STATUS = new Map([
  ["ALL", { value: ["ALL"], label: "全部" }],
  ["ACTIVE", { value: ["ACTIVE"], label: "使用中" }],
  ["AWAITING_APPOINTMENT", { value: ["AWAITING_APPOINTMENT"], label: "即將到期" }],
  ["EXPIRED", { value: ["EXPIRED"], label: "已到期" }],
  [
    "TERMINATED",
    { value: ["TERMINATED", "EARLY_TERMINATED"], label: "已結束" },
  ],
]);

// 合約方案 租購 舊手機換購
export const CONTRACT_PLAN_OPTIONS = new Map([
  ["ALL", { value: "ALL", label: "全部" }],
  ["RENTAL", { value: "租購", label: "租購" }],
  ["TRADE_IN", { value: "舊手機換購", label: "舊手機換購" }],
]);

// 繳費狀態 待繳款 繳款成功 逾期繳款，請儘速繳費 已繳款，待確認
export const PAYMENT_STATUS_OPTIONS = new Map([
  ["ALL", { value: "ALL", label: "全部" }],
  ["AWAITING_PAYMENT", { value: "AWAITING_PAYMENT", label: "待繳款" }],
  ["PAYMENT_SUCCESS", { value: "PAYMENT_SUCCESS", label: "繳款成功" }],
  ["PAYMENT_CONFIRM", { value: "PAYMENT_CONFIRM", label: "已繳款，待確認" }],
]);

//審核狀態 待審核、未通過、通過
export const REVIEW_STATUS_OPTIONS = new Map([
  ["ALL", { value: "ALL", label: "全部" }],
  ["IN_REVIEW", { value: ["IN_REVIEW"], label: "待審核" }],
  ["REJECTED", { value: ["REJECTED"], label: "未通過" }],
  [
    "ACCEPTED",
    {
      value: [
        "AWAITING_APPOINTMENT",
        "AWAITING_FORMATION",
        "CANCELLED",
        "ACTIVE",
        "EXPIRED",
        "TERMINATED",
        "EARLY_TERMINATED",
      ],
      label: "通過",
    },
  ],
]);

// 進度 (審核狀態通過後) 待預約、待取貨、已簽約、已取消
export const PROGRESS_STATUS_OPTIONS = new Map([
  ["ALL", { value: "ALL", label: "全部" }],
  [
    "AWAITING_APPOINTMENT",
    { value: ["AWAITING_APPOINTMENT"], label: "待預約" },
  ],
  ["AWAITING_FORMATION", { value: ["AWAITING_FORMATION"], label: "待取貨" }],
  [
    "ACTIVE",
    {
      value: ["ACTIVE", "EXPIRED", "TERMINATED", "EARLY_TERMINATED"],
      label: "已簽約",
    },
  ],
  ["CANCELLED", { value: ["CANCELLED"], label: "已取消" }],
]);

//合約終止原因
export const CONTRACT_TERMINATE_REASON_OPTIONS = new Map([
  ["CONTRACT_EXPIRED", { value: false, label: "合約到期" }],
  ["EARLY_TERMINATED", { value: true, label: "提前解約" }],
]);

//終止後 手機處理方式
export const TERMINATE_PHONE_OPTIONS = new Map([
  //歸還手機
  ["RETURN", { value: "RETURN", label: "歸還手機" }],
  //保留手機
  ["BUYOUT", { value: "BUYOUT", label: "保留手機" }],
]);

//帳單繳費狀態 PAYMENT_IN_PROGRESS, PAYMENT_CONFIRMING, PAYMENT_COMPLETED
export const BILL_PAYMENT_STATUS_OPTIONS = new Map([
  ["ALL", { value: ["ALL"], label: "全部" }],
  ["PAYMENT_IN_PROGRESS", { value: ["PAYMENT_IN_PROGRESS"], label: "待繳款" }],
  [
    "PAYMENT_CONFIRMING",
    { value: ["PAYMENT_CONFIRMING"], label: "待確認繳款" },
  ],
  ["PAYMENT_COMPLETED", { value: ["PAYMENT_COMPLETED"], label: "繳款成功" }],
]);

//合約成交來源STOREFRONT, WEBSITE
export const CONTRACT_SOURCE_OPTIONS = new Map([
  ["STOREFRONT", { value: "STOREFRONT", label: "門市" }],
  ["WEBSITE", { value: "WEBSITE", label: "網站" }],
]);

//繳費方式 REMITTANCE STORE_PAYMENT
export const PAYMENT_TYPE_OPTIONS = new Map([
  ["STORE_PAYMENT", { value: "STORE_PAYMENT", label: "超商繳費" }],
  ["REMITTANCE", { value: "REMITTANCE", label: "銀行轉帳" }],
]);

//門市清單 STORE_STATUS
export const STORE_STATUS_LIST = new Map([
  ["ALL", { value: "ALL", label: "全部" }],
  ["ENABLE", { value: "ENABLE", label: "營業中" }],
  ["DISABLE", { value: "DISABLE", label: "關閉" }],
]);
//門市清單 STORE_STATUS
export const STORE_STATUS = new Map([
  ["ENABLE", { value: "ENABLE", label: "營業中" }],
  ["DISABLE", { value: "DISABLE", label: "關閉" }],
  ["DELETE", { value: "DELETE", label: "刪除" }],
]);
//新增門市清單 STORE_STATUS
export const STORE_STATUS_CREATE = new Map([
  ["ENABLE", { value: "ENABLE", label: "營業中" }],
  ["DISABLE", { value: "DISABLE", label: "關閉" }],
]);

//商品管理 PRODUCT_STATUS
export const PRODUCT_STATUS = new Map([
  ["ALL", { value: "ALL", label: "全部" }],
  ["ENABLE", { value: "ENABLE", label: "上架" }],
  ["DISABLE", { value: "DISABLE", label: "下架" }],
]);

//商品管理 PRODUCT_STATUS
export const PRODUCT_STATUS_CREATE = new Map([
  ["ENABLE", { value: "ENABLE", label: "上架" }],
  ["DISABLE", { value: "DISABLE", label: "下架" }],
]);

// 最新消息狀態 上架 下架
export const NEWS_STATUS = new Map([
  ["ALL", { value: ["ENABLE", "DISABLE"], label: "全部" }],
  ["ENABLE", { value: ["ENABLE"], label: "上架" }],
  ["DISABLE", { value: ["DISABLE"], label: "下架" }],
]);

//手機容量
export const PHONE_CAPACITY = new Map([
  ["GB", { value: "GB", label: "GB" }],
  ["TB", { value: "TB", label: "TB" }],
]);

// ADMIN_ROLE
export const ADMIN_ROLE = new Map([
  ["MANAGER", { value: "MANAGER", label: "管理員" }],
  ["MEMBER", { value: "MEMBER", label: "成員" }],
]);

// ADMIN_STATUS NORMAL, FROZEN, DELETE
export const ADMIN_STATUS = new Map([
  ["ALL", { value: "ALL", label: "全部" }],
  ["NORMAL", { value: "NORMAL", label: "啟用" }],
  ["FROZEN", { value: "FROZEN", label: "停用" }],
  ["DELETE", { value: "DELETE", label: "刪除" }],
]);

// ADMIN_STATUS_SELECT
export const ADMIN_STATUS_SELECT = new Map([
  ["ALL", { value: "ALL", label: "全部" }],
  ["NORMAL", { value: "NORMAL", label: "啟用" }],
  ["FROZEN", { value: "FROZEN", label: "停用" }],
]);
// ADMIN_STATUS_MODIFY
export const ADMIN_STATUS_MODIFY = new Map([
  ["NORMAL", { value: "NORMAL", label: "啟用" }],
  ["FROZEN", { value: "FROZEN", label: "停用" }],
]);
